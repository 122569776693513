import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { HelmetDatoCms } from "gatsby-source-datocms";
import Header from "../components/header";
import { StructuredText, renderRule } from 'react-datocms';
import { isHeading, isBlockquote } from 'datocms-structured-text-utils';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Footer from "../components/footer";
import HeroBanner from '../components/hero-banner';
import MiddleBanner from '../components/middle-banner';
import ContactUs from '../components/contact-us';
import MasonryGrid from '../components/masonry';
import SubcatLink from '../components/subcat-link';
import {Helmet} from "react-helmet";

const CategoryTemplate = ({ data, pageContext }) => {
    const pageBlocks =
        data.allDatoCmsPage &&
        data.allDatoCmsPage.nodes.length > 0 &&
        data.allDatoCmsPage.nodes[0].content
            ? data.allDatoCmsPage.nodes[0].content.blocks
            : [];
    const middleBannerTwoBlocks = pageBlocks.filter(block => block.__typename === 'DatoCmsMiddleBannerCopy1');
    const fullSlug = data.allDatoCmsCategory.nodes[0]?.slug;
    const categorySlug = fullSlug ? fullSlug.split('/').pop() : '';
    const className = data.allDatoCmsPage.nodes[0].breedColumns;
    const pageTitle = data.allDatoCmsPage.nodes[0].title;
    const pageClass = className ? 'breed' : 'canil';
    const mainCategory = data.allDatoCmsCategory.nodes[0];
    const isMainCat = data.allDatoCmsCategory.nodes[0].isMainCategory;
    const blogSeo = data.datoCmsBlog;
    const siteSeo = data.datoCmsSite;
    const isBrowser = typeof window !== "undefined";
    const seoTags = data.allDatoCmsPage.nodes[0].seoMetaTags.tags;
    const seoSettings = data.allDatoCmsPage.nodes[0]?.seoSettings;
    const seoDescription = seoSettings ? seoSettings.description : '';
    const seoPageTitle = seoSettings ? seoSettings.title : '';
    const seoImage = seoSettings && seoSettings.image ? getImage(seoSettings.image.gatsbyImageData) : null;
    const seoReadibilityAnalysis = data.allDatoCmsPage.nodes[0]?.seoReadabilityAnalysis;

    if (!data.allDatoCmsPage || data.allDatoCmsPage.nodes.length === 0) {
        return (
        <>
            <div className="bg-[#021002]">
                <Helmet>
                    {isBrowser && (
                        <script>
                            {`
                                  (function(w,d,s,l,i){
                                    w[l] = w[l] || [];
                                    w[l].push({
                                      'gtm.start': new Date().getTime(),
                                      event: 'gtm.js'
                                    });
                                    var f = d.getElementsByTagName(s)[0],
                                        j = d.createElement(s), 
                                        dl = l != 'dataLayer' ? '&l=' + l : '';
                                    j.async = true;
                                    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                                    f.parentNode.insertBefore(j, f);
                                  })(window, document, 'script', 'dataLayer', 'GTM-5PR8FGM5');
                                `}
                        </script>
                    )}
                    <noscript>
                        {`
                                <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5PR8FGM5"
                                height="0" width="0" style="display:none;visibility:hidden"></iframe>
                            `}
                    </noscript>
                    

                </Helmet>
                <HelmetDatoCms
                    seo={blogSeo.seo}
                    favicon={siteSeo.favicon}
                >

                </HelmetDatoCms>
                <div className="flex text-[#e5d491] border-styles">
                    <Header/>
                </div>
                <title className="hide accessibility-hidden">{pageTitle}</title>
                <section className="flex flex-wrap justify-around gradient-main-background">
                        <title className="hide accessibility-hidden">{pageTitle}</title>
                        <h1 className="text-[#e5d491] text-6xl font-serif md:leading-[100px] flex-[0_0_100%]">
                            Estamos Trabalhando para melhorar nosso site. Volte em breve para saber mais
                        </h1>
                        {isMainCat && (
                            <SubcatLink mainCategoryId={mainCategory.id} mainCategorySlug={mainCategory.slug}/>
                        )
                        }


                        <p className="text-[#e5d491]">No associated page found for this category.</p>
                    </section>
                </div>
            </>

        );
    }

    const getCardClass = (cardSide) => {

        switch (cardSide) {
            case 'direita':
                return `page-right-${pageClass} flex flex-wrap items-center min-h-[445px] sm:mx-[30px] justify-center py-[8vw] lg:py-[8vw] lg:px-[8vw]`;
            case 'esquerda':
                return `page-left-${pageClass} flex flex-wrap items-center lg:items-start min-h-[445px] sm:mx-[30px] justify-center py-[8vw] lg:py-[8vw] lg:px-[8vw]`;
            case 'acima':
                return `page-up-${pageClass}`;
            case 'abaixo':
                return `page-down-${pageClass} flex flex-col`;
            default:
                return '';
        }
    };

    const getCardClassChild = (cardSide) => {
        switch (cardSide) {
            case 'direita':
                return 'flex-[0_0_80%] lg:flex-[0_0_100%] lg:pl-[25px]';
            case 'esquerda':
                return 'flex-[0_0_80%] lg:flex-[0_0_100%] lg:pr-[25px]';
            case 'acima':
                return 'flex-[0_0_100%]';
            case 'abaixo':
                return 'flex-[0_0_100%]';
            default:
                return '';
        }
    };

    const customRenderRules = [
        renderRule(isHeading, ({ node, children, key }) => {
            return React.createElement(
                `h${node.level}`,
                { key, className: 'font-bold font-serif' },
                children
            );
        }),
        renderRule(isBlockquote, ({ children, key }) => {
            return (
                <blockquote
                    className="font-bold mb-[25px] text-2xl text-left"
                    key={key}
                >
                    {children}
                </blockquote>
            );
        }),
    ];

    const contactPageId = data.allDatoCmsPage.nodes[0]?.contactPage?.id;
    const contactUsNode = data.allDatoCmsContact.nodes.find(node => node.id === contactPageId);
    const contactUsContent = contactUsNode ? contactUsNode.dogCategoryContactUs : null;

    const renderBlock = (block) => {
        if (block.__typename === 'DatoCmsCard') {
            return block.cardStructure.blocks.map((coluna) => {
                const cardClass = getCardClass(coluna.cardSide);
                const cardClassChild = getCardClassChild(coluna.cardSide);

                const dogWrapperClass = coluna.boxShadow ? 'dog-wrapper' : '';

                const getClassFromColSize = (numeroDeColuna) => {
                    try {
                        const colSizes = JSON.parse(numeroDeColuna);
                        let classes = '';

                        const classMappings = {
                            large: {
                                "1": "lg:flex-[0_0_100%]",
                                "2": "lg:flex-[0_0_46%]",
                                "3": "lg:flex-[0_0_30%]",
                            },
                            medium: {
                                "1": "md:flex-[0_0_100%]",
                                "2": "md:flex-[0_0_43%]",
                                "3": "md:flex-[0_0_30%]",
                            },
                            small: {
                                "1": "flex-[0_0_100%]",
                                "2": "flex-[0_0_100%]",
                                "3": "flex-[0_0_30%]",
                            }
                        };

                        for (const [breakpoint, size] of Object.entries(colSizes)) {
                            const breakpointClass = classMappings[breakpoint][size];
                            if (breakpointClass) {
                                classes += ` ${breakpointClass}`;
                            }
                        }

                        return classes.trim();
                    } catch (e) {
                        console.error("Error parsing numeroDeColuna:", e);
                        return '';
                    }
                };

                const dynamicClasses = getClassFromColSize(coluna.numeroDeColuna);
                const hasPageLinkSlug = coluna.pageLink && coluna.pageLink.slug;
                const linkUrl = hasPageLinkSlug ? `/${coluna.pageLink.slug}` : '#';

                return (

                    <div
                        className={`${cardClass} ${dynamicClasses} lg:justify-center ${dogWrapperClass} ${coluna.masonry && coluna.masonry.length > 0 ? "" : "dog-border dog-border-radius"}`}
                        key={coluna.id}
                    >
                        {hasPageLinkSlug ? (
                            <div className={`card ${coluna.masonry && coluna.masonry.length > 0 ? "bg-transparent" : "bg-[#e5d491]"} ${pageClass === 'breed' ? 'flex-[0_0_100%] relative' : 'flex-[0_0_100%] lg:flex-[0_0_50%]'}`}>

                            <div className="absolute w-full top-[-20px] flex justify-center z-50">
                                    {coluna.iconFont && (
                                        <FontAwesomeIcon
                                            icon={JSON.parse(coluna.iconFont)}
                                            className="text-xl relative dog-border p-[10px] m-[0_auto] rounded-full gradient-main-background text-[#e5d491]"
                                        />
                                    )}
                                </div>
                                <div className="p-[18px] text-base">
                                    <a href={linkUrl}
                                       className={`${cardClassChild} text-center`}>
                                        <StructuredText
                                            data={coluna.paragraphs}
                                            customNodeRules={customRenderRules}
                                        />
                                    </a>
                                </div>
                            </div>
                        ) : (
                            <div
                                className={`card ${coluna.masonry && coluna.masonry.length > 0 ? "bg-transparent h-[0]" : "bg-[#e5d491]"} flex justify-center items-center ${pageClass === 'breed' ? 'flex-[0_0_100%] relative' : 'flex-[0_0_100%] lg:flex-[0_0_50%]'}`}
                                >

                                <div className="absolute w-full top-[-20px] flex justify-center z-50">
                                    {coluna.iconFont && (
                                        <FontAwesomeIcon
                                            icon={JSON.parse(coluna.iconFont)}
                                            className="text-xl relative dog-border p-[10px] m-[0_auto] rounded-full gradient-main-background text-[#e5d491]"
                                        />
                                    )}
                                </div>
                                <div className="p-[18px] text-base lg:min-h-[225px] flex flex-col items-center justify-center">

                                    <a href="#" className={`${cardClassChild} text-center`}>
                                        <StructuredText
                                            data={coluna.paragraphs}
                                            customNodeRules={customRenderRules}
                                        />
                                    </a>
                                </div>

                            </div>
                        )}


                        {coluna.image && (
                            hasPageLinkSlug ? (
                                <a href={linkUrl} className={`${pageClass === 'breed' ? 'flex-[0_0_100%]' : 'flex-[0_0_80%] lg:flex-[0_0_50%]'} ${!coluna.boxShadow ? 'gradient-main-background' : ''}`}>
                                    <GatsbyImage
                                        image={getImage(coluna.image.gatsbyImageData)}
                                        alt={coluna.image.alt}
                                    />
                                </a>
                            ) : (

                                <a
                                    href="#"
                                    className={`${pageClass === 'breed' ? 'flex-[0_0_100%]' : 'flex-[0_0_80%] lg:flex-[0_0_50%]'} ${!coluna.boxShadow ? '' : ''}`}
                                >
                                    <GatsbyImage
                                        image={getImage(coluna.image.gatsbyImageData)}
                                        alt={coluna.image.alt}
                                    />
                                </a>

                            )
                        )}
                        {coluna.masonry && coluna.masonry.length > 0 && (
                            <MasonryGrid images={coluna.masonry} bgcolor="none" />
                        )}
                    </div>
                );
            });
        } else if (block.__typename === 'DatoCmsHeroBanner') {

            return block.bannerStructure.blocks.map((coluna) => (

                    <HeroBanner
                        key={block.id}
                        block={block}
                        pageClass={pageClass}
                    />
            ));

        } else if (block.__typename === 'DatoCmsMiddleBanner') {
            return (
                <MiddleBanner
                    key={block.id}
                    block={block}
                    categorySlug={categorySlug}
                    pageClass={pageClass}
                />
            );
        } else if (block.__typename === 'DatoCmsMiddleBannerCopy1') {
            return block.middleBannerTwo.blocks.map((coluna) => {
                const cardClass = getCardClass(coluna.cardSide);
                const cardClassChild = getCardClassChild(coluna.cardSide);
                const colSizeClass = `flex-[0_0_50%]`;

                return (
                        <div
                            className=
                                {
                                    `memory gradient-background mb-[100px] mt-[200px] p-[5px] flex justify-center items-center rounded-3xl md:rounded-none min-w-[320px] md:min-w-[480px]`
                                }
                        >
                            <div className="gradient-main-background rounded-3xl max-w-[320px] md:max-w-[420px]"
                                 key={coluna.id}
                            >
                                <div className={`card flex items-center justify-center min-h-[400px] m-[12px] md:m-[25px]`}>
                                    <div className={`flex-[0_0_100%] lg:px-[15px] lg:pb-[57px] text-[#e5d491]`}>
                                        <StructuredText
                                            data={coluna.paragraphs}
                                            customNodeRules={customRenderRules}
                                        />
                                    </div>
                                </div>
                                {coluna.image && (
                                    <div
                                        className={`relative w-full`}
                                    >
                                        <div
                                            className="p-[5px] gradient-background rounded-full absolute max-w-[130px] m-auto left-0 right-0 top-[-479px] md:top-[-510px]"
                                        >
                                            <GatsbyImage
                                                className="rounded-full max-w-[120px] max-h-[120px] gradient-main-background"
                                                image={getImage(coluna.image.gatsbyImageData)}
                                                alt={coluna.image.alt ? coluna.image.alt : "Image"}
                                            />
                                        </div>

                                    </div>
                                )}
                            </div>
                        </div>
                );
            });
        }
    };

    return (
        <div className="bg-[#021002]">
            <div className="flex text-[#e5d491] border-styles">
                <Header />
            </div>
            {seoSettings ? (
                <>
                    <HelmetDatoCms>
                        <title>{seoPageTitle || pageTitle}</title>
                        <meta name="description" content={seoDescription} />
                        {seoImage && <meta property="og:image" content={seoImage} />}
                    </HelmetDatoCms>
                    <Helmet>
                        <script type="application/ld+json">
                            {seoReadibilityAnalysis}
                        </script>
                    </Helmet>
                </>
                ) : (
                <HelmetDatoCms seo={blogSeo.seo} favicon={siteSeo.favicon}/>
    )}
            <section className={`pb-20 flex flex-wrap justify-around gradient-main-background ${pageClass} ${categorySlug}`}>
                {
                    data.allDatoCmsPage.nodes[0].tituloVisivel && (
                        <h1
                            className="
                                       keep
                                       flex
                                       justify-center
                                       items-center
                                       min-h-[77px]
                                       xsm:min-h-[18vw]
                                       sm:min-h-[15vw]
                                       text-[#e5d491]
                                       text-center
                                       text-[21px]
                                       xsm:text-[30px]
                                       sm:text-4xl
                                       md:text-5xl
                                       xl:text-6xl
                                       font-serif
                                       md:leading-[100px]
                                       flex-[0_0_100%]
                                       "
                        >
                            <title className="hide accessibility-hidden">
                                {pageTitle}
                            </title>
                            {data.allDatoCmsPage.nodes[0].title}
                        </h1>
                    )
                }
                <div className={
                        `breed-container flex flex-wrap justify-around 
                        ${pageClass === 'breed' ? 'flex-[0_0_95%] sm:flex-[0_0_85%] md:flex-[0_0_70%]' : 'flex-[0_0_100%]'}`
                    }
                >
                    {pageBlocks.length > 0 ? (
                        pageBlocks.filter(
                            block => block.__typename !== 'DatoCmsMiddleBannerCopy1').map(
                                (block) => renderBlock(block)
                        )
                    ) : (
                        <div className="p-[5%] max-w-[720px] text-[#e5d491] text-2xl">
                            Logo nosso site vai ter mais conteúdo!
                            Mas, não espere. Entre em contato pelo
                            <a href="https://api.whatsapp.com/send?phone=5511984164914" target="_blank"
                               rel="noopener noreferrer">
                                <strong className="text-bold"> whatsapp</strong>
                            </a>!
                            <p>Você pode até reservar seu filhote!</p>
                        </div>
                    )}
                </div>
                {middleBannerTwoBlocks.map(block => renderBlock(block))}
                {isMainCat && (
                    <SubcatLink mainCategoryId={mainCategory.id} mainCategorySlug={mainCategory.slug}/>
                )
                }

            </section>
            {contactUsContent && <ContactUs id={contactPageId} dogCategoryContactUs={contactUsContent} />}
            <Footer></Footer>

        </div>
    );
};

export const query = graphql`
  query($categoryId: String, $contactPageId: String) {
    allDatoCmsCategory(filter: { id: { eq: $categoryId } }) {
      nodes {
        id
        name
        slug
        isMainCategory
      }
  }
  allDatoCmsContact(filter: { id: { eq: $contactPageId } }) {
    nodes {
      id
      dogCategoryContactUs {
        blocks {
          cardStructure {
            value
            blocks {
              cardSide
              numeroDeColuna
              paragraphs {
                value
                blocks
              }
              image {
                gatsbyImageData
                alt
              }
            }
          }
        }
      }
    }
  }
    allDatoCmsPage(filter: { category: { id: { eq: $categoryId } } }) {
      nodes {
        seoSettings {
        description
        title
        twitterCard
        image {
              gatsbyImageData
              tags
              smartTags
            }
        }
        seoReadabilityAnalysis
        seoMetaTags {
           tags
        }
        id
        title
        slug
        category {
          id
        }
        contactPage {
            title
            id
        }
        title
        tituloVisivel
        breedColumns
        content {
          blocks {
            __typename
            ... on DatoCmsMiddleBannerCopy1 {
            id
            middleBannerTwo {
              blocks {
                cardSide
                image {
                  gatsbyImageData(layout: FIXED)
                  alt
                }
                numeroDeColuna
                paragraphs {
                  value
                }
              }
            }
          }
            ... on DatoCmsCard {
              id
              cardStructure {
                blocks {
                  ... on DatoCmsColuna {
                    cardSide
                    iconFont
                    numeroDeColuna
                    boxShadow
                    pageLink {
                      slug
                    }
                    id
                    paragraphs {
                      value
                    }
                    image {
                      gatsbyImageData(layout: FULL_WIDTH)
                      alt
                    }
                    masonry {
                        gatsbyImageData
                        alt
                    }
                  }
                }
              }
            }
            ... on DatoCmsHeroBanner {
              id
              bannerStructure {
                blocks {
                  ... on DatoCmsColuna {
                    cardSide
                    id
                    paragraphs {
                      value
                    }
                    image {
                      gatsbyImageData(layout: FULL_WIDTH)
                      alt
                    }
                  }
                }
              }
            }
            ... on DatoCmsMiddleBanner {
              id
              
              middleBannerStructure {
                blocks {
                  ... on DatoCmsColuna {
                    cardSide
                    id
                    iconFont
                    
                    paragraphs {
                      value
                    }
                    image {
                      gatsbyImageData
                      alt
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
   datoCmsSite {
      favicon: faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    datoCmsBlog {
      slider {
        gatsbyImageData(
            layout: FULL_WIDTH, 
            width: 1200
            )
      }
      }
  }
`;

export default CategoryTemplate;

